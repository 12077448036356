/*  imported from index.less  */

@import '../../constants/styles.less';
#meepshop {
.meepshop-meep-ui__imageText-index__root {
  height: 100%;
  position: relative;
}

.meepshop-meep-ui__imageText-index__link {
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  cursor: pointer;
}

.meepshop-meep-ui__imageText-index__overlayBackground {
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  display: flex;
  padding: 8% 10%;
  overflow: hidden;
  transition: all 0.25s ease-in;

  @media @PHONE_MEDIA {
    padding: 8%;
  }
}

.meepshop-meep-ui__imageText-index__container {
  max-width: 75%;

  @media @PHONE_MEDIA {
    max-width: 100%;
  }
}

.meepshop-meep-ui__imageText-index__header {
  line-height: 1.2em;
  letter-spacing: 0.02em;
}

.meepshop-meep-ui__imageText-index__description {
  margin: 0.2em auto 1em;
  line-height: 1.4em;
  letter-spacing: 0.05em;
}

.meepshop-meep-ui__imageText-index__button {
  display: inline-block;
  width: fit-content;
  padding: 0.4em 1em;
  line-height: 1.4em;
  letter-spacing: 0.05em;
  font-weight: 400;
  word-break: break-word;
}
}
@font-family: "Noto Sans TC", "PingFang TC", "Microsoft JhengHei", "Helvetica Neue", "Helvetica", "source-han-sans-traditional", "Arial", "sans-serif";@font-family-no-number: "Noto Sans TC", "PingFang TC", "Microsoft JhengHei", "Helvetica Neue", "Helvetica", "source-han-sans-traditional", "Arial", "sans-serif";